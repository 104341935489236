<template>
  <div>
    <base-item-group
      :item="items"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Menu',

  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapGetters(['isMaster']),
    items () {
      return {
        group: `/e/${this.id}`,
        icon: 'fa-person',
        title: this.name,
        children: [
          ...this.frontend,
          ...(this.isMaster ? this.admin : []),
          ...(this.wkacl.length > 0 ? [{ title: 'Wettkampfeingabe', to: 'wkinput', icon: 'fa-pencil' }] : [])
        ]
      }
    },
    wkacl () {
      return this.$store.state.profile?.acl?.filter(a => a.type === 'stbltftc' && a.event?._parent === this.id) || []
    },
    frontend () {
      return [
        {
          title: 'Tabellen',
          to: 'start',
          icon: 'fa-table'
        }
      ]
    },
    admin () {
      return [
        {
          title: 'Administration',
          subtitle: this.name,
          group: 'admin',
          icon: 'fa-user-cog',
          children: [
            {
              title: 'LTF bearbeiten',
              to: 'edit',
              icon: 'fa-calendar-edit'
            },
            {
              title: 'Teamchallenges bearbeiten',
              to: 'tcs',
              icon: 'fa-calendar-alt'
            }
          ]
        }
      ]
    }
  }
}

</script>

<style scoped>

</style>
